import React from "react";
import theme from "theme";
import { Theme, Image, Box, Em, Text, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Crystal Clean Carwash
			</title>
			<meta name={"description"} content={"Блиск, на який заслуговує ваше авто"} />
			<meta property={"og:title"} content={"Crystal Clean Carwash"} />
			<meta property={"og:description"} content={"Блиск, на який заслуговує ваше авто"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-3.jpg?v=2024-06-25T08:43:35.278Z"
						display="block"
						max-width="100%"
						width="350px"
						border-radius="30px"
						srcSet="https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-3.jpg?v=2024-06-25T08%3A43%3A35.278Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-3.jpg?v=2024-06-25T08%3A43%3A35.278Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-3.jpg?v=2024-06-25T08%3A43%3A35.278Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-3.jpg?v=2024-06-25T08%3A43%3A35.278Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-3.jpg?v=2024-06-25T08%3A43%3A35.278Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-3.jpg?v=2024-06-25T08%3A43%3A35.278Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-3.jpg?v=2024-06-25T08%3A43%3A35.278Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						<Em>
							Повний догляд, якого заслуговує ваш автомобіль
						</Em>
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						У Crystal Clean Carwash ми пропонуємо широкий спектр послуг, спрямованих на те, щоб ваш автомобіль виглядав і відчував себе як новий. Наша професійна команда гарантує, що ваш автомобіль отримає найвищу якість догляду. Відкрийте для себе повний набір послуг, які роблять нас найкращим вибором для автолюбителів.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
			color="--darkL1"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Комплексний догляд за автомобілем
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Наша кваліфікована та віддана команда Crystal Clean Carwash – це те, що відрізняє нас. Завдяки багаторічному досвіду роботи в галузі наш персонал навчений працювати з усіма типами транспортних засобів з максимальною обережністю та точністю. Незалежно від того, чи це розкішний седан чи міцний позашляховик, ви можете довіряти нашій команді надання найкращих послуг.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Ми надаємо широкий спектр послуг, розроблених для задоволення різноманітних потреб наших клієнтів. Кожна послуга призначена для досягнення виняткових результатів, щоб ваш автомобіль не тільки виглядав чудово, але й зберігав свою вартість.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Пакети зовнішнього миття
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Базове миття: Швидке та ефективне миття для видалення поверхневого бруду та бруду.{"\n"}
							<br />
							Deluxe Wash: включає ретельне очищення зовнішньої частини, коліс і вікон.
Преміум Wash: Наша найповніша мийка, що містить високоглянцевий віск для довготривалого блиску.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Пакети для очищення салону
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Базове очищення салону: Очищення всіх внутрішніх поверхонь пилососом і пилом.{"\n"}
							<br />
							Deluxe Interior Clean: Ретельне очищення пилососом, очищення всіх поверхонь і кондиціонування шкіряних сидінь.{"\n"}
							<br />
							Внутрішнє очищення преміум-класу: включає миття килимів шампунем, глибоке очищення всіх поверхонь і усунення запаху.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Послуги деталізації
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Зовнішні деталі: Включає корекцію фарби, обробку глиною та високоякісне вощення.{"\n"}
							<br />
							Деталі інтер’єру: Глибоке очищення оббивки, приладової панелі та всіх внутрішніх компонентів.{"\n"}
							<br />
							Повний пакет деталей: комплексна послуга, яка охоплює як зовнішні, так і внутрішні деталі для обробки виставкового залу.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Спеціалізовані послуги
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Відновлення фар: Відновлює чіткість і яскравість тьмяних або туманних фар.
Захист фарби: Нанесення захисного покриття для збереження лакофарбового покриття автомобіля.{"\n"}
							<br />
							Очищення двигуна:  безпечне та ефективне очищення моторного відсіку для видалення бруду та пилу.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Додаткові послуги
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Блиск шин: Покращує зовнішній вигляд ваших шин за допомогою глянцевого покриття.{"\n"}
							<br />
							Засіб від дощу: нанесення гідрофобного покриття на лобове скло для покращення видимості.{"\n"}
							<br />
							Видалення шерсті домашніх тварин: Спеціалізовані інструменти та методи видалення шерсті домашніх тварин з оббивки та килимів.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="space-between"
					align-self="center"
				>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							<Em>
								Це лише короткий огляд того, що ми пропонуємо в Crystal Clean Carwash. Наш повний спектр послуг призначений для задоволення всіх ваших потреб у догляді за автомобілем. Щоб отримати детальнішу інформацію або обговорити індивідуальний пакет послуг, будь ласка, зв’яжіться з нами або відвідайте наш офіс. Наш привітний персонал завжди готовий допомогти вам і забезпечити найкращий догляд за вашим автомобілем.
							</Em>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Crystal Clean Carwash – повний догляд, якого заслуговує ваш автомобіль
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Отримайте повний досвід наших пропозицій, зупинившись або зателефонувавши нам. Давайте покажемо вам, чому Crystal Clean Carwash є ідеальним місцем для любителів догляду за автомобілем.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Ми в Crystal Clean Carwash прагнемо надавати виняткові послуги та видатні результати. Довіртеся нам, щоб ваш автомобіль був у бездоганному стані. Дякуємо, що вибрали нас, і ми з нетерпінням чекаємо, щоб незабаром обслуговувати вас
				</Text>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});